import useSWR from "swr";
import { fetchMusclesSimple } from "../utils/muscleUtils";
import { useMemo } from "react";

const useMuscles = () => {
  const { data } = useSWR({ url: "muscles" }, fetchMusclesSimple);
  const musclesMemoized = useMemo(() => data, [data]);

  return { muscles: musclesMemoized };
};

export default useMuscles;
