import React from "react";
import { useUserSession } from "../../context/SessionProvider";
import { Box, CircularProgress, Typography } from "@mui/material";
import TopWorkoutTable from "../../components/TopWorkoutTable ";
import PublicIcon from "@mui/icons-material/Public";
import { Link } from "react-router-dom";

const Top50WorldPopularWorkouts = ({ limit = 50, small }) => {
  const session = useUserSession();
  if (!session?.top50mostExecutedWorkoutsRes) {
    return <CircularProgress size={30} />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: small ? 1 : 2,
        mt: small && 2,
        width: { xs: "100%", md: "auto" },
        overflowX: "auto",
      }}
      className="scrollbar-hide"
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <PublicIcon
          sx={{
            width: small ? "60px" : "100px",
            height: small ? "60px" : "100px",
          }}
        ></PublicIcon>

        <Typography
          sx={{ fontSize: { xs: "20px", md: "30px", color: "black" } }}
          fontWeight="bold"
          component={small && Link}
          to={"/app/Top50WorldPopularWorkouts"}
        >
          Top {limit} - Global
        </Typography>
      </Box>

      {Array.isArray(session.top50mostExecutedWorkoutsRes) && (
        <TopWorkoutTable
          rows={session.top50mostExecutedWorkoutsRes.slice(0, limit)}
          small={small}
        />
      )}
    </Box>
  );
};

export default Top50WorldPopularWorkouts;
