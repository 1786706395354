import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

import ItemsWithFilter from "../ItemsWithFilter";
import MenuComponent from "../MenuComponent";

const MainHeader = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const currentPath = location.pathname;
  console.log("currentPath: ", currentPath);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: {
            xs: "none", // Hide on extra small screens
            sm: "flex", // Show as flex on medium screens and up
          },
          flexDirection: "column",
          width: { xs: "80px", md: "300px" },
          minWidth: { xs: "80px", md: "300px" },
          gap: 1,
        }}
        component="header"
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: "100%",
            height: "70px",
            alignItems: "center",
            justifyContent: {
              xs: "center",
              md: "flex-start",
            },
            ml: {
              xs: 0,
              md: 1.5,
            },
            color: "black",
            textDecoration: "none",
          }}
          component={Link}
          to={"/app"}
        >
          <Box
            component={"img"}
            src="/gymobsessivelogo.png"
            alt="Bear for Bulk"
            sx={{
              width: "50px",
              height: "50px",
              borderRadius: "0.3rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            }}
          />
          <Typography
            variant="h5"
            fontWeight={"bold"}
            ml={1.5}
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            Gym Obsessive
          </Typography>
        </Box>
        <ItemsWithFilter />
        <Box
          sx={{
            display: "flex",
            borderRadius: "1rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            position: "relative",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <MenuComponent />
          <Button
            sx={{
              width: "100%",
              height: "100%",
              px: 3,
              display: "flex",
              justifyContent: "center",
              textTransform: "none",
              gap: 2,
              alignItems: "center",
              ":hover": {
                bgcolor: "rgba(0,0,0,0.05)",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                cursor: "pointer",
                textDecoration: "underline",
              },
              ":focus": {
                color: "black",
              },
              textDecoration: "none",
            }}
            onClick={() => navigate("/app/search")}
          >
            <SearchOutlinedIcon
              sx={{ textDecoration: "none", color: "black" }}
            />
          </Button>
          <Button
            sx={{
              width: "100%",
              height: "100%",
              px: 3,
              display: "flex",
              justifyContent: "center",
              gap: 2,
              alignItems: "center",
              ":hover": {
                bgcolor: "rgba(0,0,0,0.05)",
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/app")}
          >
            <HomeOutlinedIcon sx={{ textDecoration: "none", color: "black" }} />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",

            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            height: "auto",
            width: "100%",
            display: "flex",
            borderRadius: "1rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
          }}
        >
          <MenuComponent />

          <Button
            sx={{
              width: "100%",
              height: "100%",
              px: 3,
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              textTransform: "none",
              gap: 2,
              alignItems: "center",

              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
              ":focus": {
                color: "black",
                background: "rgba(0,0,0,0.15)",
              },
              textDecoration: "none",
              background: currentPath.includes("/search") && "rgba(0,0,0,0.15)",
            }}
            onClick={() => navigate("/app/search")}
          >
            <SearchOutlinedIcon
              sx={{ textDecoration: "none", color: "black" }}
            />
          </Button>

          <Button
            sx={{
              width: "100%",
              height: "100%",
              px: 3,
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              textTransform: "none",
              gap: 2,
              alignItems: "center",

              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
              ":focus": {
                color: "black",
                background: "rgba(0,0,0,0.15)",
              },
              textDecoration: "none",
              background:
                currentPath.includes("/app/library") && "rgba(0,0,0,0.15)",
            }}
            onClick={() => navigate("/app/library")}
          >
            <LibraryBooksIcon sx={{ textDecoration: "none", color: "black" }} />
          </Button>
          <Button
            sx={{
              width: "100%",
              height: "100%",
              px: 3,
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              textTransform: "none",
              gap: 2,
              alignItems: "center",

              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
              ":focus": {
                background: "rgba(0,0,0,0.15)",
                color: "black",
              },
              textDecoration: "none",
              bgcolor: currentPath === "/" && "rgba(0,0,0,0.15)",
            }}
            onClick={() => navigate("/app")}
          >
            <HomeOutlinedIcon sx={{ textDecoration: "none", color: "black" }} />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MainHeader;
