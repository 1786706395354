import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useCustomerWeight from "../../hooks/useCustomerWeight";

const CreateEditWeight = ({ weight: existingWeight, optional }) => {
  const { customer_id } = useParams();
  const { isUpdating, handleSubmitWeight } = useCustomerWeight({
    customer_id,
  });

  const [newWeight, setNewWeight] = useState(existingWeight || 0); // Initialize with existing weight if any

  const submitWeight = async () => {
    if (newWeight > 0 && newWeight < 500) {
      handleSubmitWeight(newWeight); // Call the hook's function to handle weight submission
    }
  };

  if (optional) {
    return null; // If the component is optional and not required, return null
  }

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginBottom={2}>
        {existingWeight ? "Edit" : "Set"} weight
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {/* Weight input field */}
        <TextField
          sx={{ width: "300px" }}
          type="number"
          label="New weight"
          id="weight"
          value={newWeight}
          onChange={(e) => setNewWeight(e.target.value)}
          error={!newWeight}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ marginLeft: 1 }}>
                Kg
              </InputAdornment>
            ),
          }}
        />

        {/* Submit button */}
        <Button
          disabled={isUpdating}
          sx={{
            color: "white",
            background: "black",
            ":hover": { background: "black", transform: "scale(0.95)" },
          }}
          onClick={submitWeight}
        >
          Save
        </Button>

        {/* Show loading spinner if updating */}
        {isUpdating && <CircularProgress size={30} />}
      </Box>
    </Box>
  );
};

export default CreateEditWeight;
