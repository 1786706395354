import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import {
  fetchCreateCustomerWeight,
  fetchLastCustomerWeight,
} from "../utils/customerUtils";
import { useMemo, useState } from "react";
import { toast } from "sonner";

const useCustomerWeight = ({ customer_id }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const session = useUserSession();

  const jwt = useUserJwt();

  const { data, isLoading, error, mutate, isValidating } = useSWR(
    { url: "customerWeight", customer_id: customer_id },
    () => fetchLastCustomerWeight({ jwt, customer_id })
  );

  const handleSubmitWeight = async (newWeight) => {
    setIsUpdating(true);
    const bool = await fetchCreateCustomerWeight({
      jwt,
      weight: newWeight,
    });
    if (bool) {
      await mutate(); // Refresh data after successful weight submission
      toast.success(`Weight successfully updated to ${newWeight} Kg.`);
      session.change.weight(newWeight);
    }
    setIsUpdating(false);
  };

  const customerWeightArr = useMemo(() => data, [data]);

  return {
    customerWeightArr,
    isLoading,
    isValidating,
    error,
    isUpdating,
    handleSubmitWeight,
  };
};

export default useCustomerWeight;
