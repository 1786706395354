import React, { useState, useCallback } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "sonner";
import dayjs from "dayjs";
import { useUserJwt, useUserSession } from "../../context/SessionProvider";
import { API_BASE_URL } from "../../utils";

const CreateEditBirthdate = ({ birthdate: initialBirthdate, optional }) => {
  const session = useUserSession();
  const [loading, setLoading] = useState(false);
  const [birthdate, setBirthdate] = useState(
    initialBirthdate ? dayjs(initialBirthdate) : null
  );
  const jwt = useUserJwt();

  const updateBirthdate = useCallback(
    async (newBirthdate) => {
      setLoading(true);
      if (newBirthdate && newBirthdate.isValid()) {
        const formattedBirthdate = newBirthdate.format("YYYY-MM-DD");

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + jwt);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ birthdate: formattedBirthdate });

        const requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const promise = new Promise((resolve, reject) => {
          fetch(`${API_BASE_URL}/customer/birthdate`, requestOptions)
            .then((response) => {
              if (!response.ok) {
                throw new Error("There was an error with the birthdate.");
              }
              resolve(formattedBirthdate);
              session.change.birthdate(formattedBirthdate); // Assuming session handles birthdate
            })
            .catch((error) => {
              reject(error);
            });
        });

        toast.promise(promise, {
          loading: "Updating birthdate...",
          success: (updatedBirthdate) =>
            `Birthdate successfully updated to ${updatedBirthdate}.`,
          error: (err) =>
            `Error updating birthdate: ${JSON.stringify(err?.errMsg || err)}.`,
        });
      } else {
        toast.error(`The selected birthdate "${newBirthdate}" is invalid.`);
      }
      setLoading(false);
    },
    [jwt, session]
  );

  if (optional) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          borderRadius: "0.30rem",
          boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
          p: 1,
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          marginY={1}
          sx={{ width: "100%" }}
        >
          {initialBirthdate ? "Edit" : "Set"} date of birth
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <DatePicker
            sx={{ minWidth: "300px" }}
            disabled={loading}
            label="Birthdate"
            value={birthdate}
            onChange={(newValue) => setBirthdate(newValue)}
            renderInput={(params) => (
              <TextField {...params} error={!birthdate} required fullWidth />
            )}
          />
          <Button
            onClick={() => updateBirthdate(birthdate)}
            sx={{
              maxWidth: 50,
              px: 2,
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              color: "white",
              background: "black",
              ":hover": { background: "black", transform: "scale(0.95)" },
            }}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default CreateEditBirthdate;
