import React from "react";
import { Button, Typography, Box, Container, Avatar } from "@mui/material";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100dvh",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
          padding: { xs: "0.5rem", md: "1rem" },
          backgroundColor: "#f5f5f5", // Light background color
          boxShadow: "0 25px 50px -12px rgba(0,0,0,0.1)", // Light shadow for depth
          flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on desktop
        }}
        component="header"
      >
        {/* Logo and Title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            height: "130px",
            justifyContent: { xs: "center", md: "start" }, // Centered on mobile
          }}
        >
          <Avatar
            alt="Gym Obsessive Logo"
            src="/gymobsessivelogo.png"
            sx={{
              width: 80,
              height: 80,
              display: { xs: "block", md: "inline" },
            }}
          />
          <Typography
            variant="h4"
            component="h1"
            fontWeight="bold"
            color="primary"
            textAlign={{ xs: "center", md: "left" }}
          >
            Gym Obsessive
          </Typography>
        </Box>

        {/* CTA Button */}
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: { xs: "100%", md: "150px" },
            height: "50px",
            mt: { xs: 2, md: 0 }, // Add margin on mobile
          }}
          component={Link}
          to="/app"
        >
          Go to App
        </Button>
      </Box>

      {/* Main Section */}
      <Container
        maxWidth="md"
        sx={{
          textAlign: "center",
          mt: 4,
          color: "#333",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 4, // Add some spacing between the islands
          px: { xs: 2, md: 0 }, // Add horizontal padding on mobile
        }}
      >
        {/* Island 1 */}
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "2rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
            borderRadius: "12px",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontWeight="bold"
            color="primary"
            gutterBottom
            fontSize={{ xs: "1.8rem", md: "2.125rem" }} // Responsive font size
          >
            Your Personal Gym Companion
          </Typography>
          <Typography
            variant="body1"
            component="p"
            textAlign="center"
            color="#333"
            fontSize={{ xs: "1rem", md: "1.25rem" }} // Responsive font size
          >
            Create, share, and use exercises, workouts, and routines in
            real-time. Track your progress, show it off to your friends, and
            become your strongest self.
          </Typography>
        </Box>

        {/* Island 2 */}
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "2rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
            borderRadius: "12px",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontWeight="bold"
            color="primary"
            gutterBottom
            fontSize={{ xs: "1.8rem", md: "2.125rem" }}
          >
            What Can You Do on Gym Obsessive?
          </Typography>

          <Typography
            variant="body1"
            paragraph
            fontSize={{ xs: "1rem", md: "1.25rem" }}
          >
            Gym Obsessive allows you to create personalized workouts, track your
            weight and progress in real-time, and share your routines with a
            community of fitness enthusiasts.
          </Typography>

          <Typography
            variant="body1"
            paragraph
            fontSize={{ xs: "1rem", md: "1.25rem" }}
          >
            Whether you’re tracking calories or discovering new workouts from
            around the world, Gym Obsessive is your all-in-one fitness
            companion.
          </Typography>
        </Box>
      </Container>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          padding: "1rem",
          textAlign: "center",
          width: "100%",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 25px 50px 0px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="body2" component="p">
          By using Gym Obsessive, you agree to our{" "}
          <Link to="/privacy-policy" style={{ textDecoration: "underline" }}>
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;
