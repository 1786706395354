import { Link, useNavigate, useParams } from "react-router-dom";
import { executeWorkout, useUserSession } from "../../context/SessionProvider";
import { IMG_BASE_URL, validUuid } from "../../utils";

import { toast } from "sonner";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import useWorkout from "../../hooks/useWorkout";
import WorkoutExerciseTable from "../../components/WorkoutExerciseTable";
import VerifiedIcon from "@mui/icons-material/Verified";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { deleteWorkout } from "../../utils/workoutUtils";
import WorkoutMenu from "./WorkoutMenu";

const Workout = () => {
  const { workout_id } = useParams();
  const navigate = useNavigate();
  const userSession = useUserSession();

  const {
    data,
    isLoading,
    likeDisabled,
    handleLikeWorkout,
    handleDislikeWorkout,
  } = useWorkout({
    workout_id,
  });

  const { workout, like, exercises } = data || {};

  if (!validUuid(workout_id)) {
    toast.error(
      `The workout id "${workout_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
    );
    navigate("/app");
  }

  const handleDeleteWorkout = async () => {
    try {
      const promise = deleteWorkout({ workout_id, jwt: userSession.jwt });
      toast.promise(promise, {
        loading: "Deleting workout...",
        success: (updatedStatus) => `Workout deleted succesfully.`,
        error: (err) => `Error creating workout.`,
      });
      promise.then(() => {
        userSession.deleteAllTable(workout_id, "workout_id");
        navigate("/app");
      });
    } catch (error) {
      console.error("Error deleting workout" + error);
    }
  };

  if (isLoading || !exercises || !workout) {
    return <CircularProgress />;
  }
  let time = 0;

  JSON.parse(workout.details).forEach((e) => {
    console.log("details: ");
    time =
      time +
      e.time.countdown * e.execution.sets +
      e.time.duration * e.execution.sets +
      e.time.rest * e.execution.sets +
      e.execution.sets * 3;
  });

  return (
    <Box sx={{ width: "100%", height: "100%", p: 2 }}>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <Box
          component={"img"}
          src={IMG_BASE_URL + workout.photo_url}
          alt="workout image"
          width={200}
          height={200}
          m={1}
          sx={{
            bgcolor: "rgba(0,0,0,0.1)",
            alignSelf: "center",
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            objectFit: "cover",
          }}
        ></Box>
        <Box ml={2}>
          <Typography variant="h2" fontWeight={"bold"}>
            {workout.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            pb={1}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {userSession.userSub !== workout.owner_id &&
                (like ? (
                  <FavoriteIcon
                    sx={{
                      ":hover": {
                        cursor: !likeDisabled && "pointer",
                        transform: !likeDisabled && "scale(0.95)",
                      },
                      color: "rgba(225, 8, 8)",
                      opacity: likeDisabled && "0.3",
                    }}
                    fontSize="large"
                    onClick={handleDislikeWorkout}
                  />
                ) : (
                  <FavoriteBorderIcon
                    sx={{
                      ":hover": {
                        cursor: !likeDisabled && "pointer",
                        transform: !likeDisabled && "scale(0.95)",
                      },
                      opacity: likeDisabled && "0.3",
                    }}
                    fontSize="large"
                    onClick={handleLikeWorkout}
                  />
                ))}
              <Typography ml={0.5}>{workout.likes_count} likes</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FlashOnIcon sx={{ width: "35px", height: "35px" }} />
              <Typography variant="h6" fontWeight={""}>
                {workout.energy_cost}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                {Math.round((time / 60) * 100) / 100} Min
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={15} mr={0.5}>
                    {"Created by"}{" "}
                  </Typography>

                  <Typography
                    fontSize={15}
                    component={Link}
                    to={`/app/user/${workout.owner_id}`}
                    sx={{
                      color: "black",
                      ":hover": { textDecoration: "underline" },
                    }}
                  >
                    {workout.username}
                  </Typography>
                  {workout.verified && (
                    <VerifiedIcon
                      sx={{
                        ml: 0.3,
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {userSession.userSub === workout.owner_id && (
        <WorkoutMenu
          userSession={userSession}
          handleDeleteWorkout={handleDeleteWorkout}
          workout={workout}
        />
      )}
      <Box px={1}>
        <PlayCircleIcon
          sx={{
            width: "50px",
            height: "50px",
            ":hover": { cursor: "pointer", transform: "scale(0.9)" },
          }}
          onClick={async () => {
            console.log("asd");
            const bool = await executeWorkout({
              workout_id: workout_id,
              jwt: userSession.jwt,
              country_id: userSession.customer.country_id,
            });
            if (bool) {
              userSession.initWorkoutPlayer({ workout, exercises });
            }
          }}
        />
      </Box>
      <Typography variant="h5" fontWeight="bold" mt={2}>
        Exercises table
      </Typography>
      <WorkoutExerciseTable rows={exercises} />
    </Box>
  );
};

export default Workout;
