import { API_BASE_URL } from ".";

export const fetchMusclesSimple = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}/muscle`, requestOptions);
    const result = await response.json();

    return result.data;
  } catch (error) {
    console.error(error);
  }
};
