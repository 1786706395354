import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import { fetchExerciseEce } from "../utils/exerciseUtils";
import { useMemo } from "react";

const useOneExerciseEce = ({ exercise_id }) => {
  const jwt = useUserJwt();
  const userSession = useUserSession();

  const { data, isLoading } = useSWR({ url: "exerciseEce", exercise_id }, () =>
    fetchExerciseEce({ jwt, exercise_id })
  );
  const fetchExerciseEceResMemoized = useMemo(() => data, [data]);

  console.log("use fetchExerciseEceRes exercise data: ", data);
  return {
    userSession,
    exerciseExecution: fetchExerciseEceResMemoized,
    isLoadingEce: isLoading,
  };
};

export default useOneExerciseEce;
