import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import useWorkout from "../../hooks/useWorkout";
import HomeCard from "../HomeCard";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { IMG_BASE_URL } from "../../utils";

const startWorkouts = [
  "0191f0fb-ff35-7bb0-9bc3-c925af67a4e7",
  "0191f0fd-f5c9-7f45-9780-4a92b55e51c7",
];

const NotWorkedOutYet = () => {
  const workoutOne = useWorkout({
    workout_id: startWorkouts[0],
  })?.data?.workout;

  const workoutTwo = useWorkout({
    workout_id: startWorkouts[1],
  })?.data?.workout;

  if (!workoutOne || !workoutTwo) {
    return (
      <Box>
        <Typography variant="h5" fontWeight="bold" mt={2}>
          You haven't worked out yet! Let's start right now!
        </Typography>
        <Box height={"180px"} width={"100%"}>
          <CircularProgress size={50} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold" mt={2}>
        You haven't worked out yet! Let's start right now!
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: { xs: "center", md: "flex-start" },
          mt: 1,
        }}
      >
        <HomeCard
          Icon={FitnessCenterIcon}
          name={workoutOne.name}
          grapType={false}
          RenderChildren={true}
          graph={false}
          customerData={undefined}
          to={`/app/workout/` + workoutOne?.workout_id}
        >
          <Box
            component={"img"}
            src={IMG_BASE_URL + workoutOne?.photo_url}
            alt="routine img"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "0.5rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              objectFit: "cover",
            }}
          />
        </HomeCard>
        <HomeCard
          Icon={FitnessCenterIcon}
          name={workoutTwo.name}
          grapType={false}
          RenderChildren={true}
          graph={false}
          customerData={undefined}
          to={`/app/workout/` + workoutTwo?.workout_id}
        >
          <Box
            component={"img"}
            src={IMG_BASE_URL + workoutTwo?.photo_url}
            alt="routine img"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "0.5rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              objectFit: "cover",
            }}
          />
        </HomeCard>
      </Box>
    </Box>
  );
};

export default NotWorkedOutYet;
