import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import useCustomerKcal from "../../hooks/useCustomerKcal";
import TradingGraph from "../../components/TradingGraph";
import { useParams } from "react-router-dom";
import { useUserSession } from "../../context/SessionProvider";
import CreateEditStatus from "../../components/CreateEditStatus";
import CreateEditBirthdate from "../../components/CreateEditBirthdate";
import CreateEditGender from "../../components/CreateEditGenre";
import CreateEditHeight from "../../components/CreateEditHeight";
import CreateEditActivityLevel from "../../components/CreateEditActivityLevel";
import CreateEditWeight from "../../components/CreateEditWeight";

const Kcal = () => {
  const session = useUserSession();

  const { customer_id } = useParams();

  const { customerKcalArr, isLoading, isUpdating, handleGenerateKcal } =
    useCustomerKcal({
      customer_id,
    });

  useEffect(() => {
    if (
      session?.customer?.status &&
      session?.customer?.birthdate &&
      session?.customer?.gender &&
      session?.customer?.height &&
      session?.customer?.activity_level &&
      session?.customer?.weight &&
      !session?.customer?.kcal &&
      !isUpdating.current
    ) {
      handleGenerateKcal();
    }
  }, [
    session?.customer?.status,
    session?.customer?.birthdate,
    session?.customer?.gender,
    session?.customer?.height,
    session?.customer?.activity_level,
    session?.customer?.weight,
    session?.customer?.kcal,
    handleGenerateKcal,
    isUpdating,
  ]);

  if (!customerKcalArr || isLoading) {
    return <CircularProgress size={30} />;
  }
  console.log("customerKcalArr, isLoading_ ", customerKcalArr, isLoading);

  if (
    !session?.customer?.status ||
    !session?.customer?.birthdate ||
    !session?.customer?.gender ||
    !session?.customer?.height ||
    !session?.customer?.activity_level ||
    !session?.customer?.weight
  ) {
    return (
      <Box p={3} sx={{ height: "100%" }}>
        <Typography variant="h3" fontWeight="bold" mb={3}>
          Complete your info to calculate your calories
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, pb: 3 }}>
          <CreateEditStatus
            optional={session?.customer?.status}
            status={session?.customer?.status}
          />
          <CreateEditBirthdate
            optional={session?.customer?.birthdate}
            birthdate={session?.customer?.birthdate}
          />
          <CreateEditGender
            gender={session?.customer?.gender}
            optional={session?.customer?.gender}
          />
          <CreateEditHeight
            height={session?.customer?.height}
            optional={session?.customer?.height}
          />
          <CreateEditActivityLevel
            status={session?.customer?.activity_level}
            optional={session?.customer?.activity_level}
          />
          <CreateEditWeight
            status={session?.customer?.weight}
            optional={session?.customer?.weight}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        px: 3,
      }}
    >
      <Typography variant="h2">Kcal</Typography>

      {Array.isArray(customerKcalArr) && customerKcalArr.length > 0 ? (
        <TradingGraph data={customerKcalArr} />
      ) : (
        <Typography>No data.</Typography>
      )}
    </Box>
  );
};

export default Kcal;
