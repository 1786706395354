import { createChart, ColorType } from "lightweight-charts";
import { memo, useEffect, useRef } from "react";

export const ChartComponent = memo((props) => {
  let {
    data,
    colors: {
      backgroundColor = "white",
      lineColor = "#000000",
      textColor = "#000000",
      areaTopColor = "#000000",
      areaBottomColor = "rgba(104, 104, 104, 0.28)",
    } = {},
    rightPriceScale = true,
    leftPriceScale = false,
    timeScaleVisible = true,
  } = props;
  let val = { ...data[data.length - 1] };
  let newtime = Date.now() / 1000;
  val.time = newtime;
  data = [...data, val];

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      autoSize: false,
      rightPriceScale: {
        visible: rightPriceScale, // Hides the Y-axis labels on the right side
      },
      leftPriceScale: {
        visible: leftPriceScale, // Hides the Y-axis labels on the left side (if you have one)
      },
      timeScale: {
        visible: timeScaleVisible, // Hides the time scale labels
        // Optional: other time scale options can be configured here
      },
    });
    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
    });
    newSeries.setData(data);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    leftPriceScale,
    rightPriceScale,
  ]);

  return (
    <div ref={chartContainerRef} style={{ height: "100%", width: "100%" }} />
  );
});

const TradingGraph = memo((props) => {
  console.log("TradingGraph rendered", props);
  return <ChartComponent {...props}></ChartComponent>;
});
export default TradingGraph;
