import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import {
  fetchDislikeRoutine,
  fetchLikeRoutine,
  fetchRoutineById,
} from "../utils/routineUtils";
import { useState, useCallback, useMemo } from "react";

const useRoutine = ({ routine_id }) => {
  const [likeDisabled, setLikeDisabledUi] = useState(false);
  const jwt = useUserJwt();
  const userSession = useUserSession();

  const { data, isLoading, error, mutate } = useSWR(
    { url: `routine`, routine_id },
    () => fetchRoutineById({ jwt, routine_id })
  );

  const handleChangeLike = useCallback(
    async (add) => {
      if (data && data.routine) {
        const currentLikesCount = parseInt(data.routine.likes_count) || 0;
        const updatedLikesCount = add
          ? currentLikesCount + 1
          : currentLikesCount - 1;

        const newObj = {
          ...data,
          routine: {
            ...data.routine,
            likes_count: updatedLikesCount,
          },
          like: add,
        };

        await mutate(newObj, false);
      } else {
        console.error("routine data is not available.");
      }
    },
    [data, mutate]
  );

  const handleLikeRoutine = useCallback(async () => {
    if (!likeDisabled && data) {
      setLikeDisabledUi(true);
      await handleChangeLike(true);
      userSession.addAllTable(data.routine);
      await fetchLikeRoutine({ jwt, routine_id, handleChangeLike });
      setTimeout(() => setLikeDisabledUi(false), 2000);
    }
  }, [likeDisabled, data, jwt, routine_id, handleChangeLike, userSession]);

  const handleDislikeRoutine = useCallback(async () => {
    if (!likeDisabled && data) {
      setLikeDisabledUi(true);
      await handleChangeLike(false);
      userSession.deleteAllTable(data.routine.routine_id, "routine_id");
      await fetchDislikeRoutine({ jwt, routine_id, handleChangeLike });
      setTimeout(() => setLikeDisabledUi(false), 2000);
    }
  }, [likeDisabled, data, jwt, routine_id, handleChangeLike, userSession]);

  const memoizedData = useMemo(() => data, [data]);

  return {
    data: memoizedData,
    isLoading,
    error,
    mutate,
    likeDisabled,
    setLikeDisabledUi,
    handleChangeLike,
    handleLikeRoutine,
    handleDislikeRoutine,
  };
};

export default useRoutine;
