import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import { fetchLastCustomerKcal } from "../utils/customerUtils";
import { useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import { API_BASE_URL } from "../utils";

const useCustomerKcal = ({ customer_id }) => {
  const isUpdating = useRef(false);

  const jwt = useUserJwt();
  const session = useUserSession();

  const { data, isLoading, error, mutate, isValidating } = useSWR(
    { url: "customerKcal", customer_id: customer_id }, // Exclude jwt from key
    () => fetchLastCustomerKcal({ jwt, customer_id }) // Pass jwt separately
  );

  const handleGenerateKcal = async () => {
    isUpdating.current = true;

    const promise = new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/generateKcalNumber`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "There was an error updating calories requirement."
            );
          }
          return response.json();
        })
        .then((resjson) => {
          console.log("res json kcal: ", resjson);
          const fincal = resjson.data.targetKcal;
          resolve(fincal);
          mutate(); // Refresh data after successful Kcal submission
          session.change.kcal(fincal); // Assuming session handles activity level updates
        })
        .catch((error) => {
          reject(error);
        });
    });

    toast.promise(promise, {
      loading: "Updating calories requirement...",
      success: (updatedStatus) =>
        `Calories requirement successfully updated to ${updatedStatus}.`,
      error: (err) =>
        `Error updating calories requirement: ${JSON.stringify(
          err?.errMsg || err
        )}.`,
    });

    //setIsUpdating(false);
  };

  const customerKcalArr = useMemo(() => data, [data]);

  console.log("customerKcal hook called ", data);
  return {
    customerKcalArr,
    isLoading,
    isValidating,
    error,
    isUpdating,
    handleGenerateKcal,
  };
};

export default useCustomerKcal;
