import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { IMG_BASE_URL } from "../../utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation"; // Import navigation styles
import "swiper/css/thumbs"; // Import thumbs styles

const WorkoutExerciseTable = React.memo(function WorkoutExerciseTable({
  rows,
  countdown,
  duration,
  rest,
  exerciseCounter,
  stage,
  countdownMax,
  durationMax,
  restMax,
  setWorkoutPlayer,
  workoutPlayer,
}) {
  console.log("WorkoutExerciseTable render");
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",

        border: "0.1px solid rgba(0,0,0,0.1)",
        mt: 3,
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left">Track Progress</TableCell>
            <TableCell align="left">-</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Sets</TableCell>
            <TableCell align="left">Reps</TableCell>
            <TableCell align="left">RIR</TableCell>
            <TableCell align="left">Countdown</TableCell>
            <TableCell align="left">Duration</TableCell>
            <TableCell align="left">Rest</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: "scroll" }}>
          {rows && rows.length > 0 ? (
            rows.map((row, photoIndex) => (
              <>
                {photoIndex === exerciseCounter && (
                  <TableRow sx={{ position: "relative", overflow: "hidden" }}>
                    <TableCell sx={{ overflow: "hidden" }}>
                      <LinearProgress
                        variant="determinate"
                        value={
                          stage === "countdown"
                            ? (countdown / countdownMax) * 100
                            : stage === "duration"
                            ? (duration / durationMax) * 100
                            : (rest / restMax) * 100
                        }
                        sx={{
                          position: "absolute", // Changed from 'absolute' to 'relative'
                          width: "100%",
                          left: 0,
                          bottom: 0,
                        }}
                        color={
                          stage === "countdown"
                            ? "warning"
                            : stage === "duration"
                            ? "error"
                            : "info"
                        }
                      />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow
                  key={row.size + row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    background:
                      photoIndex === exerciseCounter ? "rgba(0,0,0,0.06)" : "",
                  }}
                >
                  <TableCell align="left">{photoIndex + 1}</TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "300px",
                        display: "flex",
                        gap: 0.5,
                        flexDirection: "column",
                        justifyContent: "space-around",
                        pb: 2,
                        overflowX: "auto",
                      }}
                    >
                      {new Array(rows[photoIndex].execution.sets)
                        .fill(1)
                        .map((e, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              gap: 1,
                              justifyContent: "space-between",
                              alignItems: "center",
                              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
                              background: "rgba(0,0,0,0.1)",
                              borderTopRightRadius: "0.5rem",
                              borderBottomRightRadius: "0.5rem",
                              border:
                                rows[photoIndex].execution.seriesTrack ===
                                  index + 1 && "1px solid black",
                              borderLeft:
                                rows[photoIndex].execution.seriesTrack ===
                                index + 1
                                  ? "5px solid black"
                                  : "2px solid black",

                              p: 1,
                            }}
                          >
                            <Typography
                              color="white"
                              textAlign={"center"}
                              variant="h6"
                              sx={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", // Add black shadow with slight blur
                              }}
                            >
                              Set {index + 1}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                              }}
                            >
                              <TextField
                                sx={{
                                  zIndex: 0,
                                  background: "white",
                                  width: "90px",
                                }}
                                defaultValue={
                                  workoutPlayer.exercises[photoIndex].execution
                                    .seriesDone[index].weight
                                }
                                onChange={(e) => {
                                  setWorkoutPlayer((prev) => {
                                    let workoutPlayerCopy = Object.assign(
                                      {},
                                      prev
                                    );

                                    workoutPlayerCopy.exercises[
                                      photoIndex
                                    ].execution.seriesDone[index].weight =
                                      parseInt(e.target.value);

                                    return workoutPlayerCopy;
                                  });
                                }}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ marginLeft: 1 }}
                                    >
                                      Kg
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                color="primary"
                                sx={{
                                  width: "90px",
                                  background: "white",
                                }}
                                defaultValue={
                                  workoutPlayer.exercises[photoIndex].execution
                                    .seriesDone[index].reps
                                }
                                onChange={(e) => {
                                  setWorkoutPlayer((prev) => {
                                    let workoutPlayerCopy = Object.assign(
                                      {},
                                      prev
                                    );

                                    workoutPlayerCopy.exercises[
                                      photoIndex
                                    ].execution.seriesDone[index].reps =
                                      parseInt(e.target.value);

                                    return workoutPlayerCopy;
                                  });
                                }}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ marginLeft: 1 }}
                                    >
                                      Rep
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box>
                      <Swiper
                        modules={[Navigation, Pagination]}
                        pagination={{ clickable: true }}
                        spaceBetween={10}
                        slidesPerView={1}
                        style={{
                          width: "150px",
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "0.3rem",
                          boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
                        }}
                      >
                        {/* Map through media (images and video) */}
                        {[
                          ...(row.photo_arr ? row.photo_arr : []),
                          ...(row.video_arr ? [row.video_arr[0]] : []),
                        ].map((item, index) => (
                          <SwiperSlide key={index}>
                            {item.includes("video") ? (
                              <Box
                                component="video"
                                controls
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  zIndex: -1,
                                }}
                              >
                                <source
                                  src={IMG_BASE_URL + item}
                                  type="video/mp4"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                Your browser does not support the video tag.
                              </Box>
                            ) : (
                              <Box
                                component="img"
                                src={IMG_BASE_URL + item}
                                alt={`Media ${index}`}
                                sx={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ width: "70px" }}>{row.name}</Box>
                  </TableCell>
                  <TableCell align="left">{row.execution.sets}</TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "40px",
                      }}
                    >
                      {row.execution.reps.min === row.execution.reps.max
                        ? row.execution.reps.min
                        : `${row.execution.reps.min} - ${row.execution.reps.max}`}
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row.execution.rir}</TableCell>
                  <TableCell align="left">
                    <Box sx={{ minWidth: "100px" }}>
                      {photoIndex === exerciseCounter &&
                      stage === "countdown" ? (
                        <Typography variant="h2" fontWeight={"bold"}>
                          {countdown}
                        </Typography>
                      ) : (
                        row.time.countdown
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ minWidth: "100px" }}>
                      {photoIndex === exerciseCounter &&
                      stage === "duration" ? (
                        <Typography variant="h2" fontWeight={"bold"}>
                          {duration}
                        </Typography>
                      ) : (
                        row.time.duration
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ minWidth: "100px" }}>
                      {photoIndex === exerciseCounter && stage === "rest" ? (
                        <Typography variant="h2" fontWeight={"bold"}>
                          {rest}
                        </Typography>
                      ) : (
                        row.time.rest
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <Typography textAlign={"center"} color={"rgba(0,0,0,0.4)"}>
              No content
            </Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default WorkoutExerciseTable;
