import { useUserSession } from "../../context/SessionProvider";
import { Box, CircularProgress, Typography } from "@mui/material";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import AssessmentIcon from "@mui/icons-material/Assessment";
import HomeCard from "../../components/HomeCard";
import useCustomerWeight from "../../hooks/useCustomerWeight";
import useCustomerWater from "../../hooks/useCustomerWater";
import useCustomerExercisesExecution from "../../hooks/useCustomerExercisesExecution";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { Link, useNavigate } from "react-router-dom";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import useCustomerDiet from "../../hooks/useCustomerDiet";
import useLastRoutineExecution from "../../hooks/useMyLastRoutine";
import NotWorkedOutYet from "../../components/NotWorkedOutYet";
import Top50WorldPopularWorkouts from "../Top50WorldPopularWorkouts";
import Top50WorldPopularWorkoutsCountry from "../Top50WorldPopularWorkoutsCountry";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import useCustomerKcal from "../../hooks/useCustomerKcal";
import { memo } from "react";
import RoutineCard from "../../components/RoutineCard";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

export function formatNumberString(numberString) {
  // Convert the string to a number
  const number = parseFloat(numberString);

  // Check if the number is an integer
  if (Number.isInteger(number)) {
    return number.toString(); // Return the number as a string without decimals
  } else {
    return number.toFixed(2); // Return the number with two decimal places
  }
}

const Home = memo(({ isForViewUser = false, viewUserId = false, user }) => {
  const navigate = useNavigate();

  const session = useUserSession();
  console.log("session:  home", session);

  let userId = viewUserId ? viewUserId : session?.userSub;

  const { lastRoutineExecution, lastRoutineExecutionIsLoading } =
    useLastRoutineExecution({
      customer_id: userId,
    });

  console.log("lastRoutineExecution : ", lastRoutineExecution);
  const { customerWeightArr } = useCustomerWeight({
    customer_id: userId,
  });

  const { customerKcalArr } = useCustomerKcal({
    customer_id: userId,
  });

  const { customerWaterArr } = useCustomerWater({
    customer_id: userId,
  });
  const { customerExercisesArr, settingsCustomerExercisesArr } =
    useCustomerExercisesExecution({
      customer_id: userId,
    });
  console.log("customerExercisesArr ", customerExercisesArr);
  const { customerDietArr } = useCustomerDiet({
    customer_id: userId,
  });

  if (
    lastRoutineExecutionIsLoading ||
    !settingsCustomerExercisesArr ||
    !customerWeightArr ||
    !customerWaterArr ||
    !session ||
    !userId ||
    !session.top50mostExecutedWorkoutsRes ||
    !customerKcalArr
  ) {
    return <CircularProgress size={50} />;
  }
  console.log("session post return ", session);
  console.log("customerExercisesArr:_ ", customerExercisesArr);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        gap: 3,
        overflowY: "auto",
        p: 2,
      }}
      className="scrollbar-hide"
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 2,
          justifyContent: "space-around",
          flexWrap: "wrap",
          boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
          borderRadius: "0.30rem",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FlashOnIcon fontSize="large" />
          <Typography variant="h6" fontWeight={"bold"}>
            {isForViewUser ? user.energy : session?.customer?.energy}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ":hover": {
              cursor: "pointer",
            },
            gap: 1,
          }}
          onClick={() => {
            navigate(`/app/diet/${userId}`);
          }}
        >
          {(isForViewUser ? user.status : session?.customer?.status) ===
            "Bulk" && (
            <Box
              component={"img"}
              src="/bear.png"
              alt="Bear for Bulk"
              sx={{ width: "40px", height: "40px" }}
            />
          )}
          {(isForViewUser ? user.status : session?.customer?.status) ===
            "Definition" && (
            <Box
              component={"img"}
              src="/flamenco.png"
              alt="Flamenco for Definition"
              sx={{ width: "40px", height: "40px" }}
            />
          )}
          {(isForViewUser ? user.status : session?.customer?.status) ===
            "Recomposition" && (
            <Box
              component={"img"}
              src="/chamaleon.png"
              alt="Chameleon for Recomposition"
              sx={{ width: "40px", height: "40px" }}
            />
          )}
          <Typography
            variant="h6"
            fontWeight={"bold"}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            {(isForViewUser ? user.status : session?.customer?.status) || "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ":hover": {
              cursor: "pointer",
            },
            gap: 0.5,
          }}
          onClick={() => {
            navigate(`/app/weight/${userId}`);
          }}
        >
          <MonitorWeightIcon fontSize="large" />
          <Typography variant="h6" fontWeight={"bold"}>
            {(isForViewUser ? user.weight : session?.customer?.weight) || "-"}
            Kg
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ":hover": {
              cursor: "pointer",
            },
            gap: 0.5,
          }}
          onClick={() => {
            navigate(`/app/kcal/${userId}`);
          }}
        >
          <RamenDiningIcon fontSize="large" />
          <Typography variant="h6" fontWeight={"bold"}>
            {(isForViewUser ? user.kcal : session?.customer?.kcal) || "-"}Kcal
          </Typography>
        </Box>
        {!isForViewUser && !session?.customer?.premium && (
          <Box
            component={Link}
            to={"/app/premium"}
            sx={{
              position: "relative",
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              background:
                "radial-gradient(circle, rgba(0,0,0,0.8435749299719888) 0%, rgba(242,211,0,1) 100%)",
              color: "white",
              height: "50px",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              px: 3,
              justifyContent: "center",
              textAlign: "center",
              overflow: "hidden",
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)",
                transform: "translateX(-100%)",
                animation: "reflex 10s infinite", // Infinite reflex animation
              },
              cursor: "pointer",
              textDecoration: "none",
              ":hover": {
                transform: "scale(0.95)",
              },
              textWrap: "nowrap",
            }}
          >
            Free trial
          </Box>
        )}
      </Box>

      {lastRoutineExecution ? (
        <RoutineCard
          routine={lastRoutineExecution}
          isForViewUser={isForViewUser}
        />
      ) : (
        <Box>
          <Typography variant="h5" fontWeight="bold" mt={2}>
            {isForViewUser ? "Routine" : "Your routine"}
          </Typography>
          <HomeCard Icon={FitnessCenterIcon} name={"Routine"} to={`/app`} />
        </Box>
      )}
      <Box>
        <Typography variant="h5" fontWeight="bold" mt={2}>
          {isForViewUser ? "Metrics" : "Your metrics"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 1,
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <HomeCard
            Icon={AssessmentIcon}
            customerData={customerWeightArr}
            name={"Weight"}
            to={`/app/weight/${userId}`}
          />
          <HomeCard
            Icon={WaterDropIcon}
            customerData={customerWaterArr}
            name={"Water"}
            to={`/app/water/${userId}`}
          />
          <HomeCard
            Icon={RestaurantIcon}
            customerData={customerDietArr}
            name={"Diet"}
            to={`/app/diet/${userId}`}
            grapType="stage"
          />
          <HomeCard
            Icon={AssessmentIcon}
            customerData={customerKcalArr}
            name={"Kcal"}
            to={`/app/kcal/${userId}`}
          />
        </Box>
      </Box>
      {customerExercisesArr && Object.keys(customerExercisesArr).length > 0 && (
        <Box sx={{ overflowX: "auto" }}>
          <Typography variant="h5" fontWeight="bold" mt={2}>
            {isForViewUser ? "Strength " : "Your strength "}
            progress - 30D
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridAutoFlow: "column", // Ensure items flow in a horizontal direction
              gridTemplateRows: "repeat(2, 1fr)", // Two rows of equal height
              gridAutoColumns: "minmax(180px, 1fr)", // Ensure each column has a minimum width
              gap: 1,
              mt: 1,
              p: 1,
            }}
          >
            {customerExercisesArr &&
              Object.keys(customerExercisesArr).map((exercise) => {
                const arrActualWeight =
                  customerExercisesArr[exercise][
                    settingsCustomerExercisesArr[exercise]
                  ];
                console.log(
                  "asddd",
                  arrActualWeight[0].reps,
                  arrActualWeight[arrActualWeight.length - 1].reps
                );
                const percentageChange =
                  ((arrActualWeight[arrActualWeight.length - 1].reps -
                    arrActualWeight[0].reps) /
                    (arrActualWeight[0].reps || 1)) *
                  100;

                console.log("percentageChange ", percentageChange);

                return (
                  <HomeCard
                    exercise={arrActualWeight[0]}
                    to={`/app/exercise/${arrActualWeight[0].exercise_id}`}
                    key={exercise}
                    isExercise={true}
                    name={
                      exercise.slice(0, 16) +
                      " " +
                      (settingsCustomerExercisesArr[exercise] > 0
                        ? formatNumberString(
                            settingsCustomerExercisesArr[exercise]
                          ) + "Kg"
                        : "")
                    }
                    customerData={arrActualWeight}
                    percentageChange={
                      percentageChange ? percentageChange : false
                    }
                  />
                );
              })}
          </Box>
        </Box>
      )}

      {!isForViewUser &&
        customerExercisesArr &&
        Object.keys(customerExercisesArr).length < 1 && <NotWorkedOutYet />}
      {!isForViewUser && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 3,
            width: "100%",
          }}
        >
          <Top50WorldPopularWorkouts limit={5} small={true} />

          <Top50WorldPopularWorkoutsCountry limit={5} small={true} />
        </Box>
      )}
    </Box>
  );
});

export default Home;
