import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import {
  fetchCreateCustomerWater,
  fetchLastCustomerWater,
} from "../utils/customerUtils";
import { useMemo, useState } from "react";

const useCustomerWater = ({ customer_id }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const jwt = useUserJwt();

  const { data, isLoading, error, mutate, isValidating } = useSWR(
    { url: "customerWater", customer_id: customer_id },
    () => fetchLastCustomerWater({ jwt, customer_id })
  );

  const handleSubmitWater = async (liters) => {
    setIsUpdating(true);
    const bool = await fetchCreateCustomerWater({
      jwt,
      liters,
    });
    if (bool) {
      await mutate(); // Refresh data after successful weight submission
    }
    setIsUpdating(false);
  };
  const customerWaterArr = useMemo(() => data, [data]);

  return {
    customerWaterArr,
    isLoading,
    isValidating,
    error,
    isUpdating,
    handleSubmitWater,
  };
};

export default useCustomerWater;
