import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { IMG_BASE_URL } from "../../utils";
import { Link, useNavigate } from "react-router-dom";

const RoutineWorkoutTable = React.memo(function RoutineWorkoutTable({ rows }) {
  const navigate = useNavigate();
  console.log("Workout table rows > ", rows);
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left">-</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Day</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: "scroll" }}>
          {rows && rows.length > 0 ? (
            rows.map((row, photoIndex) => (
              <TableRow
                key={row.workout_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  color: "black",
                  textDecoration: "none",
                  ":hover": {
                    background: "rgba(0,0,0,0.1)",
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  navigate(`/app/workout/${row.workout_id}`);
                }}
              >
                <TableCell align="left">{photoIndex + 1}</TableCell>
                <TableCell align="left">
                  <Box
                    component="img"
                    sx={{ height: "80px", width: "80px" }}
                    src={IMG_BASE_URL + row.photo_url}
                    alt="preview"
                  ></Box>
                </TableCell>
                <TableCell align="left">
                  <Box sx={{ maxWidth: "70px" }}>{row.name}</Box>
                </TableCell>
                <TableCell align="left">{row.day}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                color: "black",
                textDecoration: "none",
                ":hover": {
                  background: "rgba(0,0,0,0.1)",
                },
              }}
            >
              <TableCell align="left" colSpan={4}>
                <Typography textAlign={"center"} color={"rgba(0,0,0,0.4)"}>
                  No content
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default RoutineWorkoutTable;
