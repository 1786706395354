import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import { fetchLastEce } from "../utils/exerciseUtils";
import { useMemo } from "react";

const useCustomerExercisesExecution = ({ customer_id }) => {
  const jwt = useUserJwt();

  const { data, isLoading, error, isValidating } = useSWR(
    { url: "customerExercisesArr", customer_id: customer_id },
    () => fetchLastEce({ jwt, customer_id })
  );
  const customerExercisesArrMemoized = useMemo(() => data, [data]);
  return {
    customerExercisesArr: customerExercisesArrMemoized?.customerExercisesArr,
    isLoading,
    isValidating,
    error,
    settingsCustomerExercisesArr:
      customerExercisesArrMemoized?.settingsCustomerExercisesArr,
  };
};

export default useCustomerExercisesExecution;
