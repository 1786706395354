import useSWR from "swr";
import { useUserJwt } from "../context/SessionProvider";
import {
  fetchCreateCustomerDiet,
  fetchLastCustomerDiet,
} from "../utils/customerUtils";
import { useState } from "react";

const useCustomerDiet = ({ customer_id }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const jwt = useUserJwt();

  const { data, isLoading, error, mutate, isValidating } = useSWR(
    { url: "customerDiet", customer_id: customer_id },
    () => fetchLastCustomerDiet({ jwt, customer_id })
  );

  const handleSubmitDiet = async (newDiet) => {
    setIsUpdating(true);
    const bool = await fetchCreateCustomerDiet({
      jwt,
      Diet: newDiet,
    });
    if (bool) {
      await mutate(); // Refresh data after successful Diet submission
    }
    setIsUpdating(false);
  };

  return {
    customerDietArr: data,
    isLoading,
    isValidating,
    error,
    isUpdating,
    handleSubmitDiet,
  };
};

export default useCustomerDiet;
