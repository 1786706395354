import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import {
  fetchDislikeWorkout,
  fetchLikeWorkout,
  fetchWorkoutById,
} from "../utils/workoutUtils";
import { useCallback, useMemo, useState } from "react";

const useWorkout = ({ workout_id }) => {
  const [likeDisabled, setLikeDisabledUi] = useState(false);

  const jwt = useUserJwt();
  const userSession = useUserSession();

  const { data, isLoading, error, mutate } = useSWR(
    { url: `workout`, workout_id },
    () => fetchWorkoutById({ jwt, workout_id })
  );

  const handleChangeLike = useCallback(
    async (add) => {
      if (data && data.workout) {
        const currentLikesCount = parseInt(data.workout.likes_count) || 0;
        const updatedLikesCount = add
          ? currentLikesCount + 1
          : currentLikesCount - 1;

        const newObj = {
          ...data,
          workout: {
            ...data.workout,
            likes_count: updatedLikesCount,
          },
          like: add,
        };

        await mutate(newObj, false);
      } else {
        console.error("Workout data is not available.");
      }
    },
    [data, mutate]
  );

  const handleLikeWorkout = useCallback(async () => {
    if (!likeDisabled && data) {
      await handleChangeLike(true);
      setLikeDisabledUi(true);
      userSession.addAllTable(data.workout);
      fetchLikeWorkout({
        jwt,
        workout_id,
        handleChangeLike,
      });
      setTimeout(() => {
        setLikeDisabledUi(false);
      }, 2000);
    }
  }, [likeDisabled, data, jwt, workout_id, handleChangeLike, userSession]);

  const handleDislikeWorkout = useCallback(async () => {
    if (!likeDisabled && data) {
      await handleChangeLike(false);
      setLikeDisabledUi(true);
      userSession.deleteAllTable(data.workout.workout_id, "workout_id");
      fetchDislikeWorkout({
        jwt,
        workout_id,
        handleChangeLike,
      });
      setTimeout(() => {
        setLikeDisabledUi(false);
      }, 2000);
    }
  }, [likeDisabled, data, jwt, workout_id, handleChangeLike, userSession]);
  const datamemoized = useMemo(() => data, [data]);

  return {
    data: datamemoized,
    isLoading,
    error,
    mutate,
    likeDisabled,
    setLikeDisabledUi,
    handleChangeLike,
    handleLikeWorkout,
    handleDislikeWorkout,
  };
};

export default useWorkout;
