import { ThemeProvider, useTheme } from "@aws-amplify/ui-react";
export default function AuthStyle({ children }) {
  const { tokens } = useTheme();
  const theme = {
    name: "Auth Example Theme",
    primaryColor: "black",
    secondaryColor: "black",
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay["10"]}`,
            borderWidth: "0",
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: tokens.colors.neutral["100"],
            _hover: {
              backgroundColor: tokens.colors.neutral["100"],
              border: "2px solid black",
              boxShadow: `0 0 0 5px ${tokens.colors.black}`,
            },
            _focus: {
              backgroundColor: tokens.colors.neutral["100"],
            },
            _active: {
              backgroundColor: tokens.colors.neutral["100"],
            },
          },
          _hover: {
            backgroundColor: "none",
            boxShadow: `0 0 5px 5px black`,
            border: `2px solid black`,
          },
          _focus: {
            backgroundColor: "none",
          },
          _active: {
            backgroundColor: "none",
          },
          link: {
            color: tokens.colors.black,
            _hover: {
              backgroundColor: "none",
              color: tokens.colors.black,
            },
            _focus: {
              backgroundColor: "none",
              color: tokens.colors.black,
              boxShadow: `none`,
            },
            _active: {
              backgroundColor: "none",
              color: tokens.colors.black,
              boxShadow: `none`,
            },
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${tokens.colors.black}`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.black,
            _active: {
              borderColor: tokens.colors.black,
              color: tokens.colors.black,
            },
            hover: {
              borderColor: tokens.colors.black,
              color: tokens.colors.black,
              background: "rgba(0,0,0,0)",
            },
          },
        },
      },
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
