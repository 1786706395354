import { createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef } from "react";

const TradingGraphComponentStatus = (props) => {
  const {
    phases = [
      { time: 1627689600, status: "Bulk" }, // Bulk phase times
      { time: 1627948800, status: "Definition" }, // Definition phase times
      { time: 1628121600, status: "Recomposition" }, // Recomposition phase times
    ], // Array of objects containing {time, type} for each phase
    colors: { backgroundColor = "white", textColor = "#000000" } = {},
    rightPriceScale = false, // No price scale needed
    leftPriceScale = false,
    timeScaleVisible = true,
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      autoSize: false,
      rightPriceScale: {
        visible: rightPriceScale, // Hides the Y-axis labels
      },
      leftPriceScale: {
        visible: leftPriceScale, // Hides the Y-axis labels
      },

      timeScale: {
        visible: timeScaleVisible, // Hides the time scale labels
        // Optional: other time scale options can be configured here
      },
    });

    chart.timeScale().fitContent();

    // Infer the end time for each phase based on the time of the next phase
    const getPhaseEndTime = (index) => {
      if (index + 1 < phases.length) {
        return phases[index + 1].time;
      }
      // Fallback to some default end time, e.g., 24 hours after the last phase
      return Math.floor(Date.now() / 1000); // Add one day to the last phase
    };

    // Plot phases (bulking, definition, recomposition) as background
    phases.forEach((phase, index) => {
      const end = getPhaseEndTime(index);
      const backgroundSeries = chart.addAreaSeries({
        topColor:
          phase.status === "Bulk"
            ? "rgba(255, 0, 0, 0.8)" // Bulk phase color
            : phase.status === "Definition"
            ? "rgba(255, 0, 128, 1)" // Definition phase color
            : "rgba(0, 153, 51, 1)", // Recomposition phase color
        bottomColor: "rgba(255, 255, 255, 1)",
        lineColor: "transparent", // No line color to make it purely background
      });
      backgroundSeries.setData([
        { time: phase.time, value: 0 },
        { time: end, value: 0 },
      ]);
    });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [backgroundColor, textColor, phases]);

  return (
    <div ref={chartContainerRef} style={{ height: "100%", width: "100%" }} />
  );
};

export function TradingGraphStages(props) {
  return <TradingGraphComponentStatus {...props}></TradingGraphComponentStatus>;
}
