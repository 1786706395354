import { fetchAuthSession } from "aws-amplify/auth";
import { uuidv7 } from "uuidv7";
import { mutate } from "swr"; // Make sure to import mutate if not already
import { toast } from "sonner";

export const validUuid = (uuid) => {
  if (!uuid || typeof uuid !== "string") return false;

  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regex.test(uuid);
};

export const IMG_BASE_URL = "https://gymobsessiveimages.s3.amazonaws.com/";

export const API_BASE_URL =
  "https://ldfhfia5bb.execute-api.us-east-1.amazonaws.com/dev";

export const fetchCustomer = async (userId) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/${userId}`,
      requestOptions
    );
    const responseJson = await response.json();

    return responseJson.data[0];
  } catch (error) {
    console.error("fetchCustomer error", error);
    return null;
  }
};

export const top50mostExecutedWorkouts = async function () {
  try {
    const response = await fetch(`${API_BASE_URL}/top50mostExecutedWorkouts`);
    const responseJson = await response.json();
    console.log("responseJson: top50mostExecutedWorkouts", responseJson);
    if (responseJson.status === "ok") {
      return responseJson.data;
    } else {
      throw new Error("The fetch was NOT succesfull");
    }
  } catch (error) {
    console.error("top50mostExecutedWorkouts error", error);
    throw new Error("The fetch was NOT succesfull");
  }
};

export const fetchUserSession = async ({
  userId,
  setSession,
  setWorkoutPlayer,
}) => {
  console.log("fetchUserSession");
  const [session, customer, allTables, top50mostExecutedWorkoutsRes] =
    await Promise.all([
      fetchAuthSession(),
      fetchCustomer(userId),
      fetchReadAllCreatedAndLikedTables(userId),
      //fetchExercisesByCustomerId(userId),
      top50mostExecutedWorkouts(),
    ]);
  const resFin = Object.assign(session, {
    customer,
    change: {
      username: (usr) => {
        console.log("username change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, username: usr },
        }));
      },
      creator_id: (crt_id) => {
        console.log("username change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, creator_id: crt_id },
        }));
      },
      country: (country_id, country_name) => {
        console.log("country_id change called");
        setSession((prevState) => ({
          ...prevState,
          customer: {
            ...prevState.customer,
            country_id: country_id,
            country_name: country_name,
          },
        }));
      },
      birthdate: (formattedBirthdate) => {
        console.log("birthdate change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, birthdate: formattedBirthdate },
        }));
        setTimeout(() => {
          session?.change?.generateKcal();
        }, 1000);
      },
      gender: (NewGender) => {
        console.log("gender change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, gender: NewGender },
        }));
        setTimeout(() => {
          session?.change?.generateKcal();
        }, 1000);
      },
      status: (status) => {
        console.log("status change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, status: status },
        }));
        setTimeout(() => {
          session?.change?.generateKcal();
        }, 1000);
      },
      height: (NewHeight) => {
        console.log("height change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, height: NewHeight },
        }));
        setTimeout(() => {
          session?.change?.generateKcal();
        }, 1000);
      },
      weight: (weight) => {
        console.log("weight change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, weight: weight },
        }));
        setTimeout(() => {
          session?.change?.generateKcal();
        }, 1000);
      },
      activityLevel: (NewActivityLevel) => {
        console.log("NewActivityLevel change called");
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, activity_level: NewActivityLevel },
        }));
        setTimeout(() => {
          session?.change?.generateKcal();
        }, 1000);
      },
      kcal: (targetKcal) => {
        setSession((prevState) => ({
          ...prevState,
          customer: { ...prevState.customer, kcal: targetKcal },
        }));
      },
      generateKcal: function GenerateKcal() {
        if (
          session?.customer?.status &&
          session?.customer?.birthdate &&
          session?.customer?.gender &&
          session?.customer?.height &&
          session?.customer?.activity_level &&
          session?.customer?.weight &&
          session?.customer?.kcal
        ) {
          const promise = new Promise((resolve, reject) => {
            fetch(`${API_BASE_URL}/generateKcalNumber`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${session.jwt}`,
              },
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error(
                    "There was an error updating calories requirement."
                  );
                }
                return response.json();
              })
              .then(async (resjson) => {
                console.log("res json kcal: ", resjson);
                const fincal = resjson.data.targetKcal;
                resolve(fincal);
                session.change.kcal(fincal); // Assuming session handles activity level updates
                const res = await mutate(
                  {
                    url: "customerKcal",
                    customer_id: session.userSub,
                  },
                  undefined,
                  { revalidate: true }
                );
                console.log("res1 jcak ", res);
              })
              .catch((error) => {
                reject(error);
              });
          });

          toast.promise(promise, {
            loading: "Updating calories requirement...",
            success: (updatedStatus) =>
              `Calories requirement successfully updated to ${updatedStatus}.`,
            error: (err) =>
              `Error updating calories requirement: ${JSON.stringify(
                err?.errMsg || err
              )}.`,
          });
        }
      },
    },
    allTables,
    addAllTable: function (item) {
      // Assuming setSession is defined and is a function
      setSession((prevState) => {
        return {
          ...prevState,
          allTables: [
            {
              username: customer.username,
              verified: customer.verified,
              premium: customer.premium,
              session,
              ...item,
            },
            ...prevState.allTables,
          ],
        };
      });
    },
    deleteAllTable: (item_id, idStr) => {
      setSession((prevState) => {
        console.log("deleteAllTable item_id, idStr", item_id, idStr);
        return {
          ...prevState,
          allTables: prevState.allTables.filter(
            (item) => item[idStr] !== item_id
          ),
        };
      });
    },
    initWorkoutPlayer: ({ workout, exercises }) => {
      setWorkoutPlayer({
        display: true,
        workout: workout,
        exercises: exercises,
      });
    },
    finishWorkoutPlayer: () => {
      setWorkoutPlayer({ display: false, workout: null, exercises: null });
    },
    jwt: session.tokens.idToken.toString(),
    email: session.tokens.idToken.payload.email,
    picture: session.tokens.idToken.payload.picture,
    top50mostExecutedWorkoutsRes,
  });
  setSession(resFin);
};

export const uploadFilesToS3 = async ({ jwt, imgArr, vidArr, type }) => {
  const myHeaders1 = new Headers();
  myHeaders1.append("Authorization", "Bearer " + jwt);
  myHeaders1.append("Content-Type", "application/json");

  let imgArrType = imgArr.map((img) => {
    return {
      type: img.type,
    };
  });
  const raw = {
    images: imgArrType,
    type: type,
  };
  if (vidArr) {
    raw.videos = vidArr.map((vid) => {
      return {
        type: vid.type,
      };
    });
  }

  console.log("raw :_", raw);

  const requestOptions1 = {
    method: "POST",
    headers: myHeaders1,
    body: JSON.stringify(raw),
    redirect: "follow",
  };
  let result1;
  try {
    const response = await fetch(
      API_BASE_URL + "/customer/upload",
      requestOptions1
    );
    console.log("upload s3 response: ", response);
    if (!response.ok) {
      const resJSON = await response.json();
      console.log("S3 json ", resJSON);
      throw new Error(resJSON.errMsg);
    }
    result1 = await response.json();

    console.log("result1", result1);
  } catch (error) {
    console.error("error at s3 upload", error);
    toast.error(error);
  }

  const imgProm = result1.data.imageUrls.map((url, index) => {
    console.log("map: img", url, index);
    const uploadExecution = async () => {
      console.log("imgArr[index].type: ", imgArr[index].type);
      const file = imgArr[index];
      console.log("file;: img", file);

      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": imgArr[index].type },
        body: file,
        redirect: "follow",
      };

      try {
        console.log("before imgProm");
        const response = await fetch(url, requestOptions);
        console.log("response imgProm", response);

        console.log("s3 uploaded img", response);
        return url.split("?")[0].split("amazonaws.com/")[1];
      } catch (error) {
        console.error("result1.data.imageUrls err", error);
      }
    };
    return uploadExecution();
  });
  console.log("img prom: ", imgProm);

  let vidProm = [];
  if (result1.data.videoUrls) {
    vidProm = result1.data.videoUrls.map((url, index) => {
      console.log("map: vid", url, index);

      const uploadExecution = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", vidArr[index].type);

        const file = vidArr[index];

        console.log("file;: vid", file);

        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: file,
          redirect: "follow",
        };

        try {
          const response = await fetch(url, requestOptions);
          console.log("s3 uploaded vid", response);
          return url.split("?")[0].split("amazonaws.com/")[1];
        } catch (error) {
          console.error(error);
        }
      };
      return uploadExecution();
    });
    console.log("vidProm:", vidProm);
  }
  let prom = [...imgProm, ...vidProm];
  console.log("prom ", prom);
  try {
    const res = await Promise.all(prom);
    console.log("Promise all res: ", res);
    const photo_arr = res.filter((url) => url.includes("image"));
    let video_arr = res.filter((url) => url.includes("video"));
    if (video_arr.length === 0) {
      video_arr = null;
    }
    console.log("res fin uploading", res);
    return { photo_arr, video_arr, id: result1.data.id };
  } catch (error) {
    console.log("error fin uploading", error);
  }
};

export const fetchMuscles = async ({ setMuscle }) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  try {
    const response = await fetch(API_BASE_URL + "/muscle", requestOptions);
    const responseJson = await response.json();
    if (setMuscle) {
      const categories = {};
      const groupExercisesByCategory = (muscleData) => {
        const categories = {};

        muscleData.forEach((muscle) => {
          if (!categories[muscle.category]) {
            categories[muscle.category] = [];
          }
          categories[muscle.category].push({
            muscle_id: muscle.muscle_id,
            name: muscle.name,
            category: muscle.category,
            photo_url: muscle.photo_url,
            percentaje: 0,
          });
        });

        return Object.entries(categories).map(([category, exercises]) => ({
          category,
          exercises,
        }));
      };

      const muscleCategoriesWithExercises = groupExercisesByCategory(
        responseJson.data
      );

      setMuscle(muscleCategoriesWithExercises);
    }
    return responseJson.data;
  } catch (error) {
    console.error("fetchMuscles error", error);
    return null;
  }
};

export const fetchReadAllCreatedAndLikedTables = async (customer_id) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_BASE_URL + "/readAllCreatedAndLikedTables/" + customer_id,
      requestOptions
    );
    const result = await response.json();
    console.log(result);
    console.log("fetchReadAllCreatedAndLikedTables" + JSON.stringify(result));
    return result.data.all;
  } catch (error) {
    console.error(error);
  }
};

export const fetchSearch = async ({
  setIsFetching,
  search,
  setRes,
  saveSeachInHistory,
}) => {
  const formatDate = (date) => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options)
      .format(date)
      .replace(/\//g, "-");
  };
  setIsFetching(true);
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    search,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}/search`, requestOptions);
    const result = await response.json();
    if (setRes) {
      setRes(result.data);
    }
    saveSeachInHistory({
      id: uuidv7(),
      search: search,
      date: formatDate(new Date()),
    });

    return result.data;
  } catch (error) {
    console.error("err search ", error);
  } finally {
    setIsFetching(false);
  }
};

export function debounce({ cb, delay = 2000, setIsWritting, setIsFetching }) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    setIsWritting(true);
    timer = setTimeout(() => {
      setIsWritting(false);
      cb(...args);
    }, delay);
  };
}
