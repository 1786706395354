import { useMemo } from "react";
import { useUserSession } from "../context/SessionProvider";
import { API_BASE_URL } from "../utils";
import useSWR from "swr";

const fetchtop50mostExecutedWorkoutsCountry = async function ({ country_id }) {
  if (!country_id) {
    return;
  }
  try {
    const response = await fetch(
      `${API_BASE_URL}/top50mostExecutedWorkoutsCountry/${country_id}`
    );
    const responseJson = await response.json();
    console.log("responseJson: top50mostExecutedWorkoutsCountry", responseJson);
    if (responseJson.status === "ok") {
      return responseJson.data;
    } else {
      throw new Error("The fetch was NOT succesfull");
    }
  } catch (error) {
    console.error("top50mostExecutedWorkoutsCountry error", error);
    throw new Error("The fetch was NOT succesfull");
  }
};

const useTop50WorkoutsToday = () => {
  const session = useUserSession();

  const { data } = useSWR(
    { country_id: session?.customer?.country_id },
    fetchtop50mostExecutedWorkoutsCountry
  );
  const memoizedData = useMemo(() => data, [data]);

  return {
    workouts: memoizedData,
    country_name: session?.customer?.country_name,
  };
};

export default useTop50WorkoutsToday;
