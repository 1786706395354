import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { IMG_BASE_URL } from "../../utils";
import { PieChart } from "@mui/x-charts/PieChart";

const MuscleTable = React.memo(function MuscleTable({ muscles }) {
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="muscle table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell align="left">-</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Percentage</TableCell>
            <TableCell align="left">Graph</TableCell>
            {/* New Column for Pie Chart */}
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: "scroll" }}>
          {muscles && muscles.length > 0 ? (
            muscles.map((muscle, index) => (
              <TableRow
                key={muscle.muscle_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  color: "black",
                  textDecoration: "none",
                  ":hover": {
                    background: "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">
                  <Box
                    component="img"
                    sx={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "0.30rem",
                      boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                    }}
                    src={IMG_BASE_URL + muscle.photo_url}
                    alt={muscle.name}
                  ></Box>
                </TableCell>
                <TableCell align="left">{muscle.name}</TableCell>
                <TableCell align="left">{muscle.category}</TableCell>
                <TableCell align="left">{muscle.value}%</TableCell>
                <TableCell align="left">
                  {/* Render PieChart per muscle */}
                  <PieChart
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "top", horizontal: "middle" },
                        padding: 0,
                      },
                    }}
                    width={105}
                    height={105}
                    colors={["red", "white"]} // Customize as needed
                    series={[
                      {
                        data: [
                          { name: "Active", value: muscle.value },
                          { name: "Inactive", value: 100 - muscle.value },
                        ],
                        innerRadius: 30,
                        outerRadius: 50,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        cx: 50,
                        cy: 50,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                color: "black",
                textDecoration: "none",
                ":hover": {
                  background: "rgba(0,0,0,0.1)",
                },
              }}
            >
              <TableCell align="left" colSpan={6}>
                <Typography textAlign={"center"} color={"rgba(0,0,0,0.4)"}>
                  No muscles available
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default MuscleTable;
