import {
  Box,
  CircularProgress,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce, fetchSearch } from "../../utils";
import ItemCardV2 from "../../components/ItemCard";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";

const HISTORY_KEY = "searchHistory"; // Key for localStorage

const Search = () => {
  const [search, setSearch] = useState("");
  const [isWritting, setIsWritting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);

  const [res, setRes] = useState(null);

  const saveSeachInHistory = useCallback((search) => {
    let history = JSON.parse(localStorage.getItem(HISTORY_KEY) || "[]");
    if (!history.includes(search)) {
      history = [search, ...history].slice(0, 10); // Keep only the last 5 searches
      localStorage.setItem(HISTORY_KEY, JSON.stringify(history));
      setSearchHistory(history);
    }
  }, []);

  const fetchSearchDebounce = useCallback(
    debounce({ cb: fetchSearch, setIsWritting }),
    []
  );
  const handleLogic = useCallback(
    ({ search, setRes }) => {
      fetchSearchDebounce({
        search,
        setRes,
        setIsFetching,
        saveSeachInHistory,
      });
    },
    [fetchSearchDebounce, saveSeachInHistory]
  );

  useEffect(() => {
    if (search.length > 0) {
      handleLogic({ setIsWritting, search, setRes });
    } else {
      setRes(null);
    }
  }, [search, handleLogic, saveSeachInHistory]);

  useEffect(() => {
    // Load search history from localStorage
    const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || "[]");
    setSearchHistory(history);
  }, []);

  const handleDeleteHistoryRecord = (id) => {
    // Load current history from localStorage
    let history = JSON.parse(localStorage.getItem(HISTORY_KEY) || "[]");

    // Filter out the record to delete
    history = history.filter((e) => e.id !== id);

    // Save the updated history to localStorage
    localStorage.setItem(HISTORY_KEY, JSON.stringify(history));

    // Update the state
    setSearchHistory(history);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingX: "4rem",
      }}
    >
      <TextField
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder="Search..."
        sx={{ width: "100%", maxWidth: "30rem", borderRadius: "50%", mb: 3 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isWritting && <CircularProgress size={20} />}
              {!isWritting && search && res && (
                <DeleteOutlineIcon
                  size={20}
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSearch("");
                  }}
                />
              )}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {!res && !isFetching && (
        <Box>
          <Typography variant="h5" textAlign={"center"}>
            Search History
          </Typography>
          {searchHistory.length > 0 ? (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}
            >
              {searchHistory.map((e, i) => (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    width: "100%",
                    px: 2,
                    py: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "0.25rem",
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.45)",
                    gap: 1,
                    minWidth: "300px",
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSearch(e.search);
                  }}
                >
                  <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      background: "rgba(0,0,0,0.05)",
                      border: "1px solid rgba(0,0,0,0.1)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <YoutubeSearchedForIcon fontSize="large" />
                  </Box>
                  <Typography variant="p">{e.search.slice(0, 10)}</Typography>
                  <Box sx={{ display: "flex", gap: 0.5 }}>
                    <Typography>{e.date}</Typography>
                    <DeleteOutlineIcon
                      size={20}
                      sx={{
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteHistoryRecord(e.id);
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="h6" color={"gray"} textAlign={"center"}>
              No history.
            </Typography>
          )}
        </Box>
      )}
      {isFetching &&
        Array(20)
          .fill("")
          .map((_, i) => (
            <Box
              key={i}
              sx={{
                width: "100%",
                minHeight: "70px",
                borderRadius: "0.30rem",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                gap: 1,
                mb: 1,
                p: 1,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Skeleton
                variant="rounded"
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                }}
              ></Skeleton>
              <Box
                sx={{
                  width: "70%",
                  height: "100%",
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Skeleton animation="wave" height={20} sx={{ mb: 1 }} />
                <Skeleton animation="wave" height={13} width="80%" />
              </Box>
            </Box>
          ))}
      {Array.isArray(res) &&
        res.length > 0 &&
        res.map((item) => (
          <ItemCardV2 item={item} key={JSON.stringify(item)} />
        ))}
      {res && res.length < 1 && !isFetching && search && (
        <Typography variant="h5" textAlign="center">
          No results. Search another thing!.
        </Typography>
      )}
    </Box>
  );
};

export default Search;
