import { toast } from "sonner";
import { API_BASE_URL, validUuid } from ".";

export const fetchLikeWorkout = async ({
  jwt,
  workout_id,
  handleChangeLike,
}) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      API_BASE_URL + "/workout/like/" + workout_id,
      requestOptions
    );
    if (!res.ok) {
      throw new Error("Like was not successfull");
    }

    console.log("likeWorkout res", res);
  } catch (error) {
    console.log("the error is in fetch: " + error);
    if (handleChangeLike) {
      handleChangeLike(false);
    }
  }
};

export const fetchDislikeWorkout = async ({
  jwt,
  workout_id,
  handleChangeLike,
}) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    await fetch(API_BASE_URL + "/workout/like/" + workout_id, requestOptions);
    console.log("fetchDislikeWorkout");
  } catch (error) {
    console.log("the error is in fetch: " + error);
    if (handleChangeLike) {
      handleChangeLike(true);
    }
  }
};
export const fetchWorkoutById = async ({ jwt, workout_id }) => {
  if (!validUuid(workout_id)) {
    return toast.error(
      `The workout id "${workout_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
    );
  }
  if (!jwt || !workout_id) {
    return;
  }
  console.log("fetchWorkoutById ");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_BASE_URL + "/workout/" + workout_id,
      requestOptions
    );
    const result = await response.json();
    const objFin = {};
    objFin.workout = result.data.workout;
    let exer = result.data.exercises;
    exer.map((e) => {
      e.execution.seriesDone = new Array(e.execution.sets)
        .fill(null)
        .map(() => {
          const obj = {
            weight: 0,
            reps: 0,
            trackTime: 0,
            totalTime: e.time.countdown + e.time.duration + e.time.rest + 3,
          };
          return obj;
        });
      e.execution.seriesTrack = 1;
      e.time.trackTime = 0;
      e.time.countdownMax = e.time.countdown;
      e.time.durationMax = e.time.duration;
      e.time.restMax = e.time.rest;

      e.time.totalTime =
        e.time.countdown * e.execution.sets +
        e.time.duration * e.execution.sets +
        e.time.rest * e.execution.sets +
        e.execution.sets * 3;
      return e;
    });
    objFin.exercises = exer;
    objFin.like = result.data.liked;
    return objFin;
  } catch (error) {
    console.log("the error is in fetch: " + error);
  }
};
export const deleteWorkout = async ({ jwt, workout_id }) => {
  if (!validUuid(workout_id)) {
    return toast.error(
      `The workout id "${workout_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
    );
  }
  if (!jwt || !workout_id) {
    return;
  }
  console.log("delete workout by id ");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      API_BASE_URL + "/workout/" + workout_id,
      requestOptions
    );
    if (!res.ok) {
      throw new Error("Delete workout error");
    }
  } catch (error) {
    console.log("the error is in delete workout: " + error);
    throw new Error("Delete workout error");
  }
};
