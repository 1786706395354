import { useEffect } from "react";
import { useUserSession } from "../../context/SessionProvider";

const AdSense = () => {
  const session = useUserSession();
  useEffect(() => {
    if (session?.customer?.premium === false) {
      // Dynamically load the AdSense script if user is not premium
      const script = document.createElement("script");
      script.src = "//thubanoa.com/1?z=8374320";
      script.async = "async";
      script["data-cfasync"] = "false";
      document.head.appendChild(script);
      // Dynamically import executePublicity if the script is loaded

      import("./executePublicity.js")
        .then(() => {
          console.log("executePublicity script executed.");
        })
        .catch((error) => {
          console.error("Failed to execute publicity script:", error);
        });

      return () => {
        // Cleanup the script when the component unmounts
        document.head.removeChild(script);
      };
    }
  }, [session]);
};

export default AdSense;
