import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import Search from "./routes/Search";
import Root from "./routes/Root";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import AuthStyle from "./AuthStyle";

import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import theme from "./theme";
import amplifyconfig from "./amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { Authenticator, Flex, Heading, Image } from "@aws-amplify/ui-react";

import { ThemeProvider } from "@mui/material";
import SessionProvider from "./context/SessionProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { toast, Toaster } from "sonner";
import { SWRConfig } from "swr";

import Exercise from "./routes/Excersise";
import User from "./routes/User";
import Workout from "./routes/Workout";
import Home from "./routes/Home";
import Weight from "./routes/Weight";
import YourLibrary from "./routes/YourLibrary";
import Top50WorldPopularWorkouts from "./routes/Top50WorldPopularWorkouts";
import Top50WorldPopularWorkoutsCountry from "./routes/Top50WorldPopularWorkoutsCountry";
import Routine from "./routes/Routine";
import Diet from "./routes/Diet";
import Kcal from "./routes/Kcal";
import LandingPage from "./routes/LandingPage";

// Less accessed routes
const UpdateSettings = lazy(() => import("./routes/Settings"));
const Water = lazy(() => import("./routes/Water"));
const Create = lazy(() => import("./routes/Create"));
const NotFound = lazy(() => import("./routes/NotFound"));
const PrivacyPolicy = lazy(() => import("./routes/privacy-policy"));
const CreateRoutine = lazy(() => import("./routes/Create/Routine"));
const CreateWorkout = lazy(() => import("./routes/Create/Workout"));
const CreateExercise = lazy(() => import("./routes/Create/Exercise"));
const Premium = lazy(() => import("./routes/Premium"));

Amplify.configure(amplifyconfig);

function ProtectedCom({ children }) {
  const location = useLocation();
  useEffect(() => {
    // Extract the query parameters from the URL
    const params = new URLSearchParams(location.search);
    const errorDescription = params.get("error_description");
    if (errorDescription) {
      // Decode the URL-encoded error description
      const decodedError = decodeURIComponent(errorDescription);
      toast.error(decodedError.split("error")[1]);
    }
  }, [location]);

  return (
    <AuthStyle>
      <Authenticator components={components} variation="modal">
        {({ user }) => (
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
              revalidateIfStale: false,

              /* refreshInterval: 10000, */
              fetcher: (resource, init) =>
                fetch(resource, init).then((res) => res.json()),
            }}
          >
            <SessionProvider user={user}>{children}</SessionProvider>
          </SWRConfig>
        )}
      </Authenticator>
    </AuthStyle>
  );
}

const router = createBrowserRouter([
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/",
    element: (
      <ThemeProvider theme={theme}>
        <LandingPage />
      </ThemeProvider>
    ),
  },
  {
    path: "/app",
    element: (
      <ThemeProvider theme={theme}>
        <ProtectedCom>
          <Root />
        </ProtectedCom>
      </ThemeProvider>
    ),
    children: [
      {
        path: "", // This will match "/app"
        element: <Home />,
      },
      {
        path: "diet/:customer_id", // This will match "/app/diet/:customer_id"
        element: <Diet />,
      },
      {
        path: "weight/:customer_id",
        element: <Weight />,
      },
      {
        path: "kcal/:customer_id",
        element: <Kcal />,
      },
      {
        path: "water/:customer_id",
        element: <Water />,
      },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: "premium",
        element: <Premium />,
      },
      {
        path: "library",
        element: <YourLibrary />,
      },
      {
        path: "create",
        element: <Create />,
      },
      {
        path: "create/exercise",
        element: <CreateExercise />,
      },
      {
        path: "create/workout",
        element: <CreateWorkout />,
      },
      {
        path: "create/routine",
        element: <CreateRoutine />,
      },
      {
        path: "exercise/:exercise_id",
        element: <Exercise />,
      },
      {
        path: "workout/:workout_id",
        element: <Workout />,
      },
      {
        path: "routine/:routine_id",
        element: <Routine />,
      },
      {
        path: "user/:user_id",
        element: <User />,
      },
      {
        path: "Top50WorldPopularWorkouts",
        element: <Top50WorldPopularWorkouts />,
      },
      {
        path: "top50mostExecutedWorkoutsCountry/:country_id",
        element: <Top50WorldPopularWorkoutsCountry />,
      },
      {
        path: "settings",
        element: <UpdateSettings />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const components = {
  SignIn: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
          //border="1px solid red"
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
            /* border="1px solid red" */
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            /*             border="1px solid red"
             */
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
    Footer() {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <span>
            By signing in, you agree{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener"
              style={{ color: "black" }}
            >
              {"Privacy Policy"}
            </a>
            .
          </span>
        </div>
      );
    },
  },
  SignUp: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            maxWidth="80%"
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
    Footer() {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <span>
            By signing up, you agree{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener"
              style={{ color: "black" }}
            >
              {"Privacy Policy"}
            </a>
            .
          </span>
        </div>
      );
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Suspense fallback={<></>}>
      <Toaster position="top-center" richColors closeButton expand={false} />
      <CssBaseline />
      <RouterProvider router={router} />
    </Suspense>
  </>
);
