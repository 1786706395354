import useSWR from "swr";
import { useUserJwt, useUserSession } from "../context/SessionProvider";
import {
  fetchExercise,
  fetchGiveDislikeExecise,
  fetchGiveLikeExecise,
  fetchExerciseEce,
} from "../utils/exerciseUtils";
import { useMemo, useState, useCallback } from "react";

const useExercise = ({ exercise_id }) => {
  const [likeDisabled, setLikeDisabled] = useState(false);
  const jwt = useUserJwt();
  const userSession = useUserSession();

  const { data, isLoading, error, mutate } = useSWR(
    { url: "exercise", exercise_id },
    () => fetchExercise({ jwt, exercise_id })
  );

  const fetchExerciseEceRes = useSWR(
    { url: "exerciseEce", jwt, exercise_id },
    fetchExerciseEce
  );

  const handleChangeLike = useCallback(
    (add) => {
      mutate(
        {
          ...data,
          like: add,
          exercise: {
            ...data.exercise,
            likes_count: add
              ? data.exercise.likes_count + 1
              : data.exercise.likes_count - 1,
          },
        },
        false
      );
    },
    [data, mutate]
  );

  const handleLikeExercise = useCallback(async () => {
    if (likeDisabled) return;
    setLikeDisabled(true);
    handleChangeLike(true);

    const success = await fetchGiveLikeExecise({
      jwt,
      exercise_id,
      handleChangeLike,
    });

    if (success) {
      userSession.addAllTable(data?.exercise);
    }

    setTimeout(() => {
      setLikeDisabled(false);
    }, 2000);
  }, [likeDisabled, handleChangeLike, jwt, exercise_id, data, userSession]);

  const handleDislikeExercise = useCallback(async () => {
    if (likeDisabled) return;
    setLikeDisabled(true);
    handleChangeLike(false);

    const success = await fetchGiveDislikeExecise({
      jwt,
      exercise_id,
      handleChangeLike,
    });

    if (success) {
      userSession.deleteAllTable(data?.exercise.exercise_id, "exercise_id");
    }

    setTimeout(() => {
      setLikeDisabled(false);
    }, 2000);
  }, [likeDisabled, handleChangeLike, jwt, exercise_id, data, userSession]);

  const exerciseMemoized = useMemo(() => data, [data]);
  const fetchExerciseEceResMemoized = useMemo(
    () => fetchExerciseEceRes,
    [fetchExerciseEceRes]
  );

  return {
    mutate,
    isLoading,
    error,
    data: exerciseMemoized,
    exercise: exerciseMemoized?.exercise,
    like: exerciseMemoized?.like,
    likeDisabled,
    setLikeDisabled,
    handleChangeLike,
    handleLikeExercise,
    handleDislikeExercise,
    userSession,
    exerciseExecution: fetchExerciseEceResMemoized.data,
    isLoadingEce: fetchExerciseEceResMemoized.isLoading,
  };
};

export default useExercise;
