import React, { useState, useCallback } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { toast } from "sonner";
import { useUserJwt, useUserSession } from "../../context/SessionProvider";
import { API_BASE_URL } from "../../utils";

const CreateEditUsername = ({ username: usr, optional }) => {
  const session = useUserSession();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(usr);
  const jwt = useUserJwt();

  const updateUsername = useCallback(
    async (newUsername) => {
      setLoading(true);
      if (
        newUsername &&
        newUsername.length > 0 &&
        newUsername.length < 41 &&
        newUsername !== usr
      ) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + jwt);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ username: newUsername });

        const requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const promise = new Promise((resolve, reject) => {
          fetch(`${API_BASE_URL}/customer/username`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              resolve(result.data[0].username);
              session.change.username(newUsername);
            })
            .catch((error) => {
              reject(error);
            });
        });

        toast.promise(promise, {
          loading: "Editing username...",
          success: (updatedUsername) =>
            `Username successfully edited to ${updatedUsername}.`,
          error: (err) =>
            `Error editing username: ${JSON.stringify(err?.errMsg || err)}.`,
        });
      } else {
        toast.error(`The username "${newUsername}" is invalid.`);
      }
      setLoading(false);
    },
    [usr, jwt]
  );

  if (optional) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginBottom={2}>
        {usr ? "Edit" : "Create"} username
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <TextField
          error={!username}
          required
          disabled={loading}
          sx={{ width: "300px" }}
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Button
          onClick={() => updateUsername(username)}
          sx={{
            maxWidth: 50,
            px: 2,
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            color: "white",
            background: "black",
            ":hover": { background: "black", transform: "scale(0.95)" },
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditUsername;
